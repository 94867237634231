import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'RMovies';

  

  // c8cf90b3c1a02ebf6f1161de9455d673

  // https://api.themoviedb.org/3/movie/550?api_key=c8cf90b3c1a02ebf6f1161de9455d673

  // eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjOGNmOTBiM2MxYTAyZWJmNmYxMTYxZGU5NDU1ZDY3MyIsInN1YiI6IjYwOTE3MmJhOGRiYzMzMDA3NjBiNjRhZSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.RmWmzkClffdNNMi7h3v1dFSvM3Oce6lZgwFdVY-xJZ8
}
