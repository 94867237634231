// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  moviesURL: 'https://api.themoviedb.org/3/discover/movie?page=5',
  searchMoviesURL: 'https://api.themoviedb.org/3/search/movie?query=',
  movieDetailsURL: 'https://api.themoviedb.org/3/movie/',
  tvShowsURL: 'https://api.themoviedb.org/3/tv/popular?page=2',
  searchTvShowsURL:'https://api.themoviedb.org/3/search/tv?query=',
  tvShowDetailsURL:'https://api.themoviedb.org/3/tv/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
